import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { apiUrl } from '../../constants/defaultValue';

import { printDocument } from '../../utils/PrintDocument';
import bmcLogo from '../Assets/BMC-logo.jpeg';
import hdfcLogo from '../Assets/hdfc.png';
import './PdfDownload.css';
import { uploadFile } from 'react-s3';
import { config } from '../Form/EarlyBirdForm';

const PDFTemplate = ({ details, getOrders }) => {
    const [challanMax, setChallanMax] = useState(true)
    const [orders, setOrders] = useState([])
    console.log(details)
    let {
        TxnId,
        bankTransactionId,
        time,
        home,
        name,
        selectedScheme,
        selectedSubScheme,
        orderId,
        accountNo,
        street,
        pincode,
        fileNo,
        mob,
        paymentMode,
        amount,
        paymentStatus
    } = details;

    const getCurrentDate = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }

    useEffect(() => {
        if (Object.keys(details).length === 0) {
            name = 'Srikanta';
            selectedScheme = 'BUILDING PLAN FEE';
            selectedSubScheme = 'SCRUTINY FEE';
            fileNo = 12;
            mob = 9876543712;
            paymentMode = 'Offline';
        }
        amount = 126;
    }, [])
    return (
        <div className='container d-flex flex-column' >
            <div className="my-5 text-center">
                <button onClick={() => printDocument('divToPrint', orderId)} className="btn btn-primary  rounded">Download Receipt</button>
            </div>
            <p
                onClick={() => getOrders()}
                className="text-center w-auto my-2 mx-1 py-1"
                style={{ color: '#FF0000', cursor: 'pointer', textDecoration: "underline" }}
            >
                Update / View Payments
            </p>

            <div id="divToPrint" style={{ width: '35cm', height: '65cm', display: "none" }} >
                <div className='d-flex justify-content-between align-items-center p-3 challan'>
                    <div className='mx-2 border px-4 pt-5 position-relative'
                        style={!challanMax ? { height: '200px', overflowY: 'scroll' } : {}}
                    >
                        <div>
                            <img src="assets/img/hc.png" ></img>

                            {/* <h1 className='mx-2 text-center'>Orissa High Court</h1> */}
                            <h1 className='mx-2 text-center'>ePay Receipt</h1>
                        </div>

                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p>Date :</p>
                            <p>{new Date(details?.createdAt)?.toDateString()} {new Date(details?.createdAt)?.toLocaleTimeString()}</p>
                        </div>

                        <div className='text-center my-1'>
                            <p className='font-weight-bold'>Order - {orderId}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <img src="https://www.orissahighcourt.nic.in/logo.png" alt='BMC Logo' className='h-25 w-25' />
                        </div>
                        <div className='my-3'>
                            <p className='my-2'>Application Details</p>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td className='text-start'>Case Type</td>
                                        <td className='text-end'>{details?.selectedCaseType?.label}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Case No</td>
                                        <td className='text-end'>{details.caseNo}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Case Year</td>
                                        <td className='text-end'>{details.caseYear}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Payee Name</td>
                                        <td className='text-end'>{details.applicantName}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Contact Number</td>
                                        <td className='text-end'>{details.phoneNumber}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='d-flex justify-content-between align-items-center my-4'>
                                <p>Payment Details</p>
                            </div>
                        </div>
                        <div>
                            <table className="table border my-3">

                                {/* <td>{dt?.awf_amount ? dt?.awf_amount : "-"}</td>
                                                        <td>{dt?.sawf_amount ? dt?.sawf_amount : "-"}</td>
                                                        <td>{dt?.lwf_amount ? dt?.lwf_amount : "-"}</td>
                                                        <td>{dt?.totalPayableAmount}</td> */}
                                <tbody>
                                    <tr>
                                        <td>Court Fee</td>
                                        <td>{details?.courtFee}</td>
                                    </tr>
                                    <tr>
                                        <td>Vakalatnama</td>
                                        <td>{details?.awf_amount ? details?.awf_amount : "-"}</td>
                                    </tr>

                                    <tr>
                                        <td>State Bar Council Advocates' Welfare Fund</td>
                                        <td>{details?.sbc_amount ? details?.sbc_amount : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>High Court Bar Association Advocates' Welfare Fund</td>
                                        <td>{details?.hba_amount ? details?.hba_amount : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>High Court Bar Association Sr. Advocates' Welfare Fund</td>
                                        <td>{details?.sawf_amount ? details?.sawf_amount : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Advocate Clerk's Welfare Fund</td>
                                        <td>{details?.acw_amount ? details?.acw_amount : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>High Court Legal Assistants Association Welfare Fund</td>
                                        <td>{details?.lwf_amount ? details?.lwf_amount : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Affidavit</td>
                                        <td>{details?.affidavit_amount ? details?.affidavit_amount : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Notary Fees (Special Adhesive)</td>
                                        <td>{details?.nfs_amount ? details?.nfs_amount : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Amount</td>
                                        <td>{details.totalPayableAmount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='my-3'>

                            <div>
                                <p className='font-weight-bold text-center'></p>
                                <p className=''>Transaction Details</p>
                                <table className="table border my-4">
                                    <tbody>
                                        <tr>
                                            <th>Payment Type</th>
                                            <td>Online Paid</td>
                                        </tr>
                                        <tr>
                                            <th>Order ID</th>
                                            <td>{orderId}</td>
                                        </tr>
                                        <tr>
                                            <th>Txn. ID</th>
                                            <td>{details?.bankTransaction?.epayBankTxnId}</td>
                                        </tr>
                                        <tr>
                                            <th>Bank Ref ID</th>
                                            <td>{details?.bankTransaction?.bankTransaction?.bank_ref_no}</td>
                                        </tr>
                                        <tr>
                                            <th>Date</th>
                                            <td>{getCurrentDate()}</td>
                                        </tr>
                                        <tr>
                                            <th>Time</th>
                                            <td>{time}</td>
                                        </tr>
                                        <tr>
                                            <th>Payment Status</th>
                                            <td>{paymentStatus}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default PDFTemplate