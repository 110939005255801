import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from 'react-select';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { rawOptions } from './options';
import axios from 'axios';
import { apiUrl } from '../../constants/defaultValue';


const UpdateCase = ({ modalOpen, toggleModal, selectedCase, getAllVerifiedPayments }) => {
    const [caseNo, setCaseNo] = useState('');
    const [caseType, setCaseType] = useState();
    const [caseYear, setCaseYear] = useState('');
    const [loading, setLoading] = useState(false);
    const [caseTypeOptions, setCaseTypeOptions] = useState([]);


    const handleUpdate = async () => {
        try {
            setLoading(true);
            const token = await localStorage.getItem('token');

            const config = {
                method: 'post',
                url: `${apiUrl}/epay/order/updateCase`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"
                },
                data: {
                    caseNo,
                    selectedCaseType: caseType,
                    caseYear,
                    id: selectedCase?._id,
                    api_key: "registeruser"
                }
            }
            const response = await axios(config);
            if (response.data.code === 200) {
                NotificationManager.success(
                    null,
                    response.data.message,
                    3000,
                    null,
                    null,
                    ''
                );
                await getAllVerifiedPayments();
                toggleModal();
                setLoading(false);
            }
            else {
                NotificationManager.error(
                    null,
                    response.data.message,
                    3000,
                    null,
                    null,
                    ''
                );
                toggleModal();
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            NotificationManager.error(
                null,
                error.message,
                3000,
                null,
                null,
                ''
            );
            toggleModal();
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log({ selectedCase })
        setCaseNo(selectedCase?.caseNo);
        setCaseYear(selectedCase?.caseYear);

        if (rawOptions) {

            let dataAfterSplit = rawOptions.data.split("~")
            console.log(dataAfterSplit)

            dataAfterSplit.shift();
            dataAfterSplit.shift();
            console.log(dataAfterSplit)
            let optionHolder = []
            for (let index = 0; index < dataAfterSplit.length; index++) {
                let element = dataAfterSplit[index];
                let option = {
                    key: index,
                    value: element.split("#")[1],
                    label: element.split("#")[0],
                    data: element
                }
                optionHolder.push(option)
            }
            setCaseTypeOptions([...optionHolder])
            setCaseType(optionHolder.filter((item) => item?.value === selectedCase?.selectedCaseType?.value));

            console.log({ optionHolder })
        }


    }, [selectedCase])


    return (
        <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader style={{ width: '100%' }} className=''>
                <div className=' d-flex justify-content-between align-items-center'>
                    <Label className='flex-start'>Update Case</Label>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className='my-2'>
                    <Label>Case Number</Label>
                    <Input value={caseNo} onChange={(e) => setCaseNo(e.target.value)} />

                </div>
                <div className='my-2'>
                    <Label>Case Year</Label>
                    <Input value={caseYear} onChange={(e) => setCaseYear(e.target.value)} />

                </div>
                <div className='my-2'>
                    <Label>Case Type</Label>
                    <Select value={caseType} options={caseTypeOptions} onChange={(e) => setCaseType(e)} />

                </div>
            </ModalBody>
            <ModalFooter>
                <ButtonGroup>
                    <button className='btn btn-sm btn-danger rounded mx-1' onClick={toggleModal}>
                        Cancel
                    </button>
                    <button
                        className={`btn btn-secondary btn-shadow btn-multiple-state ${loading ? 'show-spinner' : ''
                            }`}
                        size="sm"
                        onClick={handleUpdate}
                    >
                        <span className="spinner d-inline-block">
                            <span className="bounce1" />
                            <span className="bounce2" />
                            <span className="bounce3" />
                        </span>
                        <span className="label">
                            Update
                        </span>
                    </button>
                </ButtonGroup>
            </ModalFooter>
            <NotificationContainer />
        </Modal>
    )
}

export default UpdateCase;